:root {

  /* Colors */
  --yellow: #ffc71e;
  --gray: #414042;
  --teal: #64a19d;
  --red: #e92527;
  --purple: #7464a1;
  --green: #38b24a;
  --cyan: #1cabc4;

  --white: #fff;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --black: #000;

  --font-size-l: 24px;
  --font-size-m: 18px;
  --font-size-xl: 40px;
  --font-size-xxl: 48px;
  --font-size-xxxl: 50px;
  --font-size-xxxxl: 60px;

  --font-family-azo_sans-bold: "Azo Sans-Bold", Helvetica;
  --font-family-azo_sans-medium: "Azo Sans-Medium", Helvetica;
  --font-family-montserrat: "Montserrat", Helvetica;
}