@import url("https://use.typekit.net/nws7qkp.css");

h1 {
  font-family: azo-sans-web, sans-serif;
  font-weight: 700;
  font-style: normal;
}

h2 {
  font-family: azo-sans-web, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

h4 {
  font-family: azo-sans-web, sans-serif;
  font-size: var(--font-size-l);
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  color: var(--gray)
}

p {
  font-family: montserrat, sans-serif;
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
  color: var(--gray-700);
}

body {
  margin: 0;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}